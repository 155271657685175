import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import s from "./styles.module.css";

export default function Order() {
    // const { product } = useParams();

    return (
        <>
            <Row>
                <Col className={s.title}>
                    პროდუქტის შესაძენად მოგვწერე{" "}
                    <Link
                        className="underline"
                        to="https://facebook.com/eufy.ge"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Facebook
                    </Link>
                    -ზე ან დაგვირეკე:{" "}
                    <Link
                        to="tel:574180111"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        574 180 111
                    </Link>
                </Col>
            </Row>
        </>
    );
}
