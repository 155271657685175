import Container from "react-bootstrap/Container";
import s from "./styles.module.css";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();
    return (
        <footer className={s.footer}>
            <Container>
                {/* <div className={s.sectionTitle}>Buy on the eufy store</div>
                    <ul className={s.sectionBody}>
                        <li>
                            <p>
                                <a href="/policies/shipping-policy?ref=footer">
                                    Fast, Free Shipping
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <a href="/policies/refund-policy?ref=footer">
                                    30-Day Money-Back Guarantee
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <a href="https://us.eufy.com/pages/exchange?ref=footer">
                                    Hassle-Free Warranty
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <a href="/contact?ref=footer">
                                    Lifetime Customer Support
                                </a>
                            </p>
                        </li>
                    </ul>

                    <hr /> */}

                <div className={s.sectionTitle}>{t("footer.contact")}</div>
                <ul className={s.sectionBody}>
                    <div>
                        <li>
                            <img
                                src="/images/facebook.svg"
                                alt="Facebook"
                                className={s.icon}
                            />
                            <p className={s.iconPrimaryText}>
                                <a
                                    href="https://www.facebook.com/eufy.ge"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Facebook
                                </a>
                            </p>
                        </li>
                        <li>
                            <img
                                src="/images/instagram.svg"
                                alt="Instagram"
                                className={s.icon}
                            />
                            <p className={s.iconPrimaryText}>
                                <a
                                    href="https://www.instagram.com/eufy.ge"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Instagram
                                </a>
                            </p>
                        </li>
                        <li>
                            <img
                                src="/images/tiktok.svg"
                                alt="TikTok"
                                className={s.icon}
                            />
                            <p className={s.iconPrimaryText}>
                                <a
                                    href="https://www.tiktok.com/@eufy.ge"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    TikTok
                                </a>
                            </p>
                        </li>
                    </div>
                    <li>
                        <img
                            src="/images/email.svg"
                            alt="ელ. ფოსტა"
                            className={s.icon}
                        />
                        <p className={s.iconPrimaryText}>
                            <a href="mailto:info@eufy.ge">info@eufy.ge</a>
                        </p>
                    </li>
                    <li>
                        <img
                            src="/images/phone.svg"
                            alt="ტელ. ნომერი"
                            className={s.icon}
                        />
                        <p className={s.iconPrimaryText}>
                            <a href="tel:574180111">574 180 111</a>
                        </p>
                    </li>
                </ul>

                <hr className="mt-3" />

                <div className={s.sectionTitle}>{t("footer.download-app")}</div>
                <ul className={s.sectionBody}>
                    <div className={s.download}>
                        <img
                            src="/images/eufy-security-app.png"
                            alt="Eufy Security App"
                            className={s.appIcon}
                        />
                        <div>
                            <a
                                href="https://apps.apple.com/us/app/eufy-security/id1424956516"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/images/app-store.svg"
                                    alt="App Store"
                                    className={s.downloadIcon}
                                />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.oceanwing.battery.cam"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/images/google-play.svg"
                                    alt="Google Play"
                                    className={s.downloadIcon}
                                />
                            </a>
                        </div>
                    </div>
                </ul>
            </Container>
        </footer>
    );
}

// function DesktopFooter({ setShowModal }) {
//     const { t } = useTranslation();

//     return (
//         <Row className="d-none d-sm-none d-md-flex">
//             <Col className="mt-3" sm={5}>
//                 <div className="mb-2 social-icons-container">
//                     <Link
//                         to="https://facebook.com/eufy.ge"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         <img
//                             className="social-icons"
//                             src="/images/facebook.svg"
//                             alt="Facebook logo"
//                         />
//                     </Link>
//                     <Link
//                         to="https://m.me/101178736342121"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         <img
//                             className="social-icons"
//                             src="/images/messenger.svg"
//                             alt="Messenger logo"
//                         />
//                     </Link>
//                     <Link
//                         to="https://www.youtube.com/@CARUP_OFFICIAL"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         <img
//                             className="social-icons"
//                             src="/images/youtube.svg"
//                             alt="Youtube logo"
//                         />
//                     </Link>
//                 </div>
//                 <p
//                     className="mb-1"
//                     style={{
//                         fontSize: "18px",
//                         color: "var(--carup-primary-blue)",
//                     }}
//                 >
//                     <Link
//                         to="tel:574180111"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         📞 574 180 111
//                     </Link>
//                 </p>
//                 <p
//                     className="mb-1"
//                     style={{
//                         fontSize: "18px",
//                         color: "var(--carup-primary-blue)",
//                     }}
//                 >
//                     <Link
//                         to="mailto:info@eufy.ge"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         📬 info@eufy.ge
//                     </Link>
//                 </p>
//             </Col>
//             <Col
//                 className="mt-3"
//                 xs={12}
//                 md={7}
//                 style={{
//                     textAlign: "right",
//                 }}
//             >
//                 <p
//                     className="mb-1"
//                     style={{
//                         fontSize: "18px",
//                     }}
//                 >
//                     ყოველდღე 10:00 - 19:00
//                 </p>
//                 <p
//                     className="mb-1"
//                     style={{
//                         fontSize: "18px",
//                     }}
//                 >
//                     <Link to="#" onClick={() => setShowModal(true)}>
//                         კორპორაციული შეთავაზება
//                     </Link>
//                 </p>
//                 <p
//                     className="mb-1"
//                     style={{
//                         fontSize: "18px",
//                     }}
//                 >
//                     <Link
//                         to="/terms-and-conditions.pdf"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         {t("terms-and-conditions")}
//                     </Link>
//                 </p>
//                 <p
//                     style={{
//                         fontSize: "18px",
//                     }}
//                 >
//                     <Link to="/privacy-policy">{t("privacy-policy")}</Link>
//                 </p>
//             </Col>
//         </Row>
//     );
// }

// function MobileFooter({ setShowModal }) {
//     const { t } = useTranslation();

//     return (
//         <Row className="d-flex d-md-none text-center">
//             <Col className="mt-3">
//                 <div className="mb-2 social-icons-mobile-container">
//                     <Link
//                         to="https://facebook.com/eufy.ge"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         <img
//                             className="social-icons"
//                             src="/images/facebook.svg"
//                             alt="Facebook logo"
//                         />
//                     </Link>
//                     <Link
//                         to="https://m.me/101178736342121"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         <img
//                             className="social-icons"
//                             src="/images/messenger.svg"
//                             alt="Messenger logo"
//                         />
//                     </Link>
//                     <Link
//                         to="https://www.youtube.com/@CARUP_OFFICIAL"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         <img
//                             className="social-icons"
//                             src="/images/youtube.svg"
//                             alt="Youtube logo"
//                         />
//                     </Link>
//                 </div>
//                 <p
//                     className="mb-1"
//                     style={{
//                         fontSize: "18px",
//                         color: "var(--carup-primary-blue)",
//                     }}
//                 >
//                     <Link
//                         to="tel:574180111"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         📞 574 180 111
//                     </Link>
//                 </p>
//                 <p
//                     style={{
//                         fontSize: "18px",
//                         color: "var(--carup-primary-blue)",
//                     }}
//                 >
//                     <Link
//                         to="mailto:info@eufy.ge"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         📬 info@eufy.ge
//                     </Link>
//                 </p>
//                 <hr
//                     style={{
//                         margin: "unset",
//                     }}
//                 />
//                 <p
//                     className="mb-1 mt-3"
//                     style={{
//                         fontSize: "18px",
//                     }}
//                 >
//                     ყოველდღე 10:00 - 19:00
//                 </p>
//                 <p
//                     className="mb-1"
//                     style={{
//                         fontSize: "18px",
//                     }}
//                 >
//                     <Link to="#" onClick={() => setShowModal(true)}>
//                         კორპორაციული შეთავაზება
//                     </Link>
//                 </p>
//                 <p
//                     className="mb-1"
//                     style={{
//                         fontSize: "18px",
//                     }}
//                 >
//                     <Link
//                         to="/terms-and-conditions.pdf"
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         {t("terms-and-conditions")}
//                     </Link>
//                 </p>
//                 <p
//                     style={{
//                         fontSize: "18px",
//                     }}
//                 >
//                     <Link to="/privacy-policy">{t("privacy-policy")}</Link>
//                 </p>
//             </Col>
//         </Row>
//     );
// }
